import React,{useState} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

function IndexPage( {data}) {
  const [visible, setVisible] = useState(false)

  return (
    // <Layout bg="none">
    <Layout>
      <SEO
        // keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Thomas Grau"
      />
      <div className="flex flex-col flex-1 w-full max-w-4xl px-4 my-8 md:mx-auto">
      <section className="flex flex-col justify-center flex-1">
          <div className="my-2 font-bold text-center">
            <Img
              className="mx-auto rounded-full shadow-2xl grau-portrait"
              fluid={data.imageFluid.childImageSharp.fluid} 
              />
            <h1 className="w-full text-5xl text-center md:text-6xl">
              Hallo, ich bin Thomas&nbsp;Grau.
            </h1>
            <p className="max-w-3xl mx-auto text-xl font-light md:text-3xl ">
              Maschinenbau-Ingenieur mit Passion für neue Technologien, Online Marketing und das Internet
            </p>
        </div>
      </section>
      </div>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    imageFluid: file(relativePath: { eq: "grau_thomas.png" }) {
      childImageSharp {
            # i.e. the max width of your container is 700 pixels.
            #
            # Other options include maxHeight (set both maxWidth and maxHeight to crop),
            # grayscale, duotone, rotate, etc.
            fluid(quality: 90, 
                  maxWidth: 384,
                  sizes: "(min-width: 768px) 384px, 256px",
                  srcSetBreakpoints: [100,200,256,384]
                  ){
              # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
              # ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluid_withWebp_noBase64
              # ...GatsbyImageSharpFluid
              # ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
      }
    },
    imageMobile: file(relativePath: { eq: "grau_thomas.png" }) {
      childImageSharp {
        fixed(
           quality: 90, 
           width: 256
          ){
              ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    imageDesktop: file(relativePath: { eq: "grau_thomas.png" }) {
      childImageSharp {
        fixed(
           quality: 90, 
           width: 384
          ){
              ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
`